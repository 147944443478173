<template>
  <div class="h-screen">
    <!-- Forgot actions -->
    <div class="flex-col-center justify-center">
      <div class="w-full my-5 md:my-28">
        <div class="absolute top-5 right-5">
          <SelectLangs />
        </div>

        <div class="w-full mx-auto sm:max-w-md mb-7">
          <Brand size="normal" />
        </div>

        <!-- Form -->
        <div class="w-full mx-auto sm:max-w-md px-5 sm:p-0">
          <Card>
            <template #title>
              <div class="mx-6 pt-4">
                <!-- Form title -->
                <h2 class="text-lg font-semibold">{{ $t("invite.invitationDropdown") }}</h2>
              </div>
            </template>
            <template #content>
              <div class="flex flex-col w-full">
                <div v-if="invitationForm.busy">
                  <Skeleton height="2rem" class="mb-2" />
                  <Skeleton height="2rem" class="mb-2" />
                  <Skeleton height="2rem" class="mb-2" />
                  <Skeleton height="2rem" class="mb-2" />
                  <Skeleton height="2rem" class="mb-2" />
                </div>
                <div v-else>
                  <div v-if="invitations.length">
                    <div v-for="(invitation, invitationIndex) of invitations" :key="invitationIndex">
                      <div class="w-full flex-center mb-3">
                        <label class="form-label-inline flex-1">{{ invitation.project.company }}</label>
                        <Button v-if="invitation.is_pending" type="button" :label="$t('invite.acceptBtn')" @click="invitationAccepted(invitation, invitationIndex)" :loading="invitation.form.busy" iconPos="right" class="p-button-primary mr-auto" />
                        <Button v-else type="button" :label="$t('invite.toProject')" @click="toProject(invitation.project_id)" iconPos="right" class="p-button-primary mr-auto" />
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p class="text-sm">{{ $t("There are no pending invitations") }}</p>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>

        <!-- Don't have projects? -->
        <div v-if="user.config?.project_default_id" class="flex-col-center mt-3">
          <router-link :to="{ name: 'Onboarding redirect' }">
            <Button :label="$t('goToMyProjects')" class="p-button-secondary p-button-text" />
          </router-link>
        </div>
        <div v-else class="flex-col-center mt-3">
          <router-link :to="{ name: 'Onboarding project' }">
            <Button :label="$t('createMyFirstProject')" class="p-button-secondary p-button-text" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Framework components
import Card from "primevue/card"
import Button from "primevue/button"
import Skeleton from "primevue/skeleton"

import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"

// App components
import Brand from "@/components/Brand"
import SelectLangs from "@/components/SelectLangs"

const sprintf = require("sprintf-js").sprintf

export default {
  name: "Invitations",
  components: {
    // Framework components
    Card,
    Button,
    Skeleton,

    // App components
    Brand,
    SelectLangs
  },

  data() {
    return {
      token: this.$route.query?.accessToken || localStorage.getItem("Token"),
      user: {},
      invitationForm: new FormClient(),
      invitations: []
    }
  },

  async mounted() {
    if (!this.token || this.token == "null") {
      this.$router.push({ name: "Login" })
    }

    await this.login(this.token)
    await this.getInvitations()
  },

  methods: {
    async login(token) {
      await this.$store.dispatch("Auth/setToken", token)
      const response = await new FormClient().get(ApiRoute.auth.profile)
      await this.$store.dispatch("Auth/setUser", response.data)
      this.user = response.data.data
    },

    async getInvitations() {
      const response = await this.invitationForm.get(sprintf(ApiRoute.user.invitations, { user: this.user.id }))
      this.invitations = response.data.data
        .filter((invitation) => invitation.is_pending)
        .map((invitation) => {
          invitation.form = new FormClient()
          return invitation
        })
    },

    async invitationAccepted(invitation, index) {
      this.invitations[index].is_pending = false
      await invitation.form.get(sprintf(ApiRoute.user.acceptInvitation, { invitation_id: invitation.id, user: this.user.id }))
    },

    toProject(projectId) {
      this.$router.push({ name: "Dashboard", params: { id: projectId } })
    }
  }
}
</script>
